<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 10.2094 20.4771 8.54087 19.5756 7.1389C19.0934 6.38902 18.5029 5.71538 17.8262 5.1401"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8926 14.0742C10.8926 13.4681 10.9678 12.985 11.1182 12.625C11.2686 12.265 11.5648 11.8708 12.0068 11.4424C12.4535 11.0094 12.736 10.7018 12.8545 10.5195C13.0368 10.2415 13.1279 9.94076 13.1279 9.61719C13.1279 9.1888 13.0208 8.86296 12.8066 8.63965C12.597 8.41178 12.2871 8.29785 11.877 8.29785C11.485 8.29785 11.1683 8.40951 10.9268 8.63281C10.6898 8.85156 10.5713 9.15007 10.5713 9.52832H8.91016C8.91927 8.72168 9.19271 8.08366 9.73047 7.61426C10.2728 7.14486 10.9883 6.91016 11.877 6.91016C12.793 6.91016 13.5062 7.14258 14.0166 7.60742C14.5316 8.07227 14.7891 8.72168 14.7891 9.55566C14.7891 10.2985 14.4427 11.0299 13.75 11.75L12.9092 12.5771C12.6084 12.9189 12.4535 13.418 12.4443 14.0742H10.8926ZM10.7764 16.2002C10.7764 15.9313 10.8607 15.7148 11.0293 15.5508C11.1979 15.3822 11.4258 15.2979 11.7129 15.2979C12.0046 15.2979 12.2347 15.3844 12.4033 15.5576C12.5719 15.7262 12.6562 15.9404 12.6562 16.2002C12.6562 16.4508 12.5742 16.6605 12.4102 16.8291C12.2461 16.9977 12.0137 17.082 11.7129 17.082C11.4121 17.082 11.1797 16.9977 11.0156 16.8291C10.8561 16.6605 10.7764 16.4508 10.7764 16.2002Z"
      :fill="accent"
    />
  </svg>
</template>
